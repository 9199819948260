import React from "react";
import Modal from "../../../components/Modal";
import { IoIosClose } from "react-icons/io";

const VideoModal = (props) => {
  return (
    <Modal show={props.show}>
      <div>
        <IoIosClose
          style={{
            float: "right",
            fontSize: "30px",
            color: "#fff",
            cursor: "pointer",
          }}
          onClick={props.close}
        />
        <div style={{ padding: 32, background: "#000" }}>
          <iframe
            width={props.isMobile ? "300" : "560"}
            height={props.isMobile ? "auto" : "315"}
            src={props.link}
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </Modal>
  );
};

export default VideoModal;
